import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security } from '@okta/okta-react';
import { setAppLoader } from '../redux/app/actions';
import { clearRedis } from "../services/appServices";
import { clearSession, DATADOG_LOG_TYPE, datadogLogger } from './utilCommon';
import { removeAppCookies } from "./manageCookies";

export let PROTACTED_HOME_PAGE = '/MyASTM';

// Setup Configuration for OktaAuth. 
export const authClient = new OktaAuth({
    issuer: process.env.REACT_APP_OKTA_ISSUER,
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true, // [true] provides a modern solution for protecting SPAs. 
    postLogoutRedirectUri: process.env.REACT_APP_LOGOUT_URI,
    disableHttpsCheck: window.location.hostname !== 'localhost' ? true : false, // [True] if working on https environment.
    tokenManager: {
        expireEarlySeconds: 60,
        autoRenew: true, // autorenew [true] auto update access token
        secure: window.location.hostname !== 'localhost' ? true : false, // This option is only relevant if storage is set to cookie and false option is only for testing
    },
    services: {
        autoRenew: true,
        autoRemove: true,
    }
});

export const handleLoggedIn = async (redirectURL = '', isSessionClear = true) => {
    let redirectCallbackURL = '';
    if (redirectURL) {
        redirectCallbackURL += `?callbackUrl=${redirectURL}`
    }
    const genratedRedirectURL = process.env.REACT_APP_LOGIN_PAGE_REQUEST + window.btoa(window.location.origin + redirectCallbackURL);

    //Remove all storage and cookies before redirect to login page
    if(isSessionClear) {
        clearSession();
        removeAppCookies(false);
    }

    window.location.assign(genratedRedirectURL);
}

let isHandleLogoutRedirectCalled = false;
export const handleLoggedOut = async (dispatch, redirectURL = '') => {
    if (isHandleLogoutRedirectCalled) {
        return;
    }
    dispatch(setAppLoader(true));
    clearRedis(async () => {
        isHandleLogoutRedirectCalled = true;
        clearSession();
        removeAppCookies(false);
        window.localStorage.setItem('isMemberStillLoginInAnotherTab', false)
        await authClient.signOut();
        handleLoggedIn(redirectURL, false);
    });
}

export const redirectUrlHandler = () => {
    const redirectUrl = sessionStorage.getItem("REDIRECT_URL");
    return redirectUrl || null;
}

export const oktaExceptionHandler = (error) => {
    datadogLogger(
        `OAuthError or AuthSdkError Error: ${error.name}`,
        {
            error,
            config: error.config,
        },
        DATADOG_LOG_TYPE.error,
        {
            error
        }
    );
    clearRedis(() => {
        clearSession();
        removeAppCookies(false);
        verifySessionAndGetToken();
    });
}

const oktaWithRedirectHandler = () => {
    const hashValue = window.location.hash;
    const sessionToken = hashValue ? window.location.hash.split('#')[1] : false;
    try {
        authClient.token.getWithRedirect({
            scopes: authClient.options.scopes,
            ...(sessionToken && {sessionToken})
        });
    } catch (error) {
        oktaExceptionHandler(error);
    }
}

export const verifySessionAndGetToken = async () => {
    const isSessionExist = await authClient.session.exists();
    if (!isSessionExist) {
        const callbackUrl = redirectUrlHandler();
        if (callbackUrl) {
            handleLoggedIn(callbackUrl);
        } else {
            handleLoggedIn(window.location.pathname + window.location.search);
        }
    } else {
        setTimeout(() => {
            const idToken = authClient.getIdToken();
            if (!idToken) {
                oktaWithRedirectHandler();
            }
        }, 1000);
    }
};

export const OktaSecurityHOC = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const hashValue = window.location.hash;

    React.useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const isRedirectFromRM = urlParams.get('rmLogout');
        if (isRedirectFromRM) {
            (async () => {
                const isSessionExist = await authClient.session.exists();
                if (!isSessionExist) {
                    handleLoggedOut(dispatch, '/RMLogin');
                } else {
                    window.location.replace(window.location.origin + '/RMLogin');
                }
            })();
        }
        // https://github.com/okta/okta-auth-js/issues/1365#issuecomment-1387698694
        (async () => {
            await authClient.start();
        })();

        return () => {
            (async () => {
                await authClient.stop();
            })();
        }
    }, []);

    React.useEffect(() => {
        if (hashValue) {
            let sessionToken = window.location.hash.split('#')[1];
            if (sessionToken === 'IdentityDelegationRefreshToken') {
                clearSession();
                window.location.replace(window.location.origin);
            } else {
                const urlParams = new URLSearchParams(window.location.search);
                const redirectUrlParams = urlParams.get('callbackUrl');
                window.sessionStorage.setItem("REDIRECT_CALLBACK_URL", window.location.pathname?.length ? redirectUrlParams : PROTACTED_HOME_PAGE);
                oktaWithRedirectHandler();
            }
        }
    }, [hashValue]);

    if (hashValue) {
        return null;
    }

    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        const redirectUrl = window.sessionStorage.getItem("REDIRECT_CALLBACK_URL");
        history.replace(toRelativeUrl(redirectUrl || originalUri || '/', window.location.origin));
        window.sessionStorage.removeItem("REDIRECT_CALLBACK_URL");
    };

    const handleAuth = () => {
      if (!hashValue) {
        verifySessionAndGetToken();
      }
    }

    return (
        <Security 
            oktaAuth={authClient}
            onAuthRequired={handleAuth}
            restoreOriginalUri={restoreOriginalUri}
        >
            <Route path="/auth" component={LoginCallback} />
            { props.children }
        </Security>
    );
}

// TODO : get and renew auth token header.
export const getAuthHeaderToken = async () => {
    try {
      const tokenInfo = await authClient.tokenManager.get("accessToken");
      // To check token is valid.
      if (tokenInfo) {
          if (authClient.tokenManager.hasExpired(tokenInfo)) {
              const renewAccessToken = await authClient.token.renew(tokenInfo);
              return renewAccessToken ? renewAccessToken.accessToken : null;
          } else {
              return tokenInfo.accessToken;
          }
      } else {
          return null;
      }
    } catch (error) {
      oktaExceptionHandler(error);
    }
};